<template>
    <div class="page-gray">
        <van-tabs
            v-model="active"
            sticky
            swipeable
            :line-width="15"
            :line-height="4">
            <van-tab title="已绑定" name="0">
                <binding-list></binding-list>
            </van-tab>
            <van-tab title="未绑定" name="1">
                <unbinding-list></unbinding-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
 import bindingList from "@/views/electronicNameTicker/bindingList";
 import UnbindingList from "@/views/electronicNameTicker/unbindingList";
export default {
    name: "electronicNameTickerList",
    components: {
        UnbindingList,
        bindingList
    },
    data () {
        return {
            active: '0'
        }
    }
}

</script>
<style lang="less" scoped>

</style>
