<template>
    <div class="list">
        <div class="bindcard">
            <div class="tips1">您尚未绑定电子姓名贴</div>
            <div class="tips2">绑定电子姓名贴， 校服区分找回都不缺</div>
            <div class="button">去绑定</div>
        </div>
        
        <div class="title">未绑定订单</div>
       <div class="item" v-for="item in list">
           <div class="card" >
               <img src="./../../assets/images/wbd.png"  class="unbinding" />
               <div class="top">
                   <van-image></van-image>
                   <div class="content">
                       <div class="name">长郡雨外洪塘学校小学部秋装男女同款</div>
                       <div class="detail">
                           <div class="time">2022年2月11日  11:00</div>
                       </div>
                   </div>
               </div>
           </div>
           
          
       </div>
    </div>
</template>

<script>
export default {
    name: "UnbindingList",
    
    data() {
        return {
            list: [{
                isOpen: true
            }, {
                isOpen: false
            }, {
                isOpen: false
            }]
        }
    }
}
</script>

<style scoped lang="less">
.list {
    padding: 0 11px;
    .bindcard {
        margin: 10px 0;
        width: 355px;
        height: 121px;
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 0px rgba(224,224,224,0.5000);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tips1 {
            font-size: 13px;
            color: #999999;
            line-height: 18px;
        }
        .tips2 {
            font-size: 13px;
            color: #FFA42B;
            line-height: 18px;
            margin-top: 5px;
        }
    }
    
    .button {
        width: 120px;
        height: 30px;
        background: #0273FF;
        border-radius: 6px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 17px;
    }
    .title {
        line-height: 12px;
        font-size: 12px;
        color: #999999;
       
    }
    .item {
        margin: 10px 0;
    }
    .card {
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 0px rgba(224,224,224,0.5000);
        border-radius: 8px;
        padding: 12px 8px;
        position: relative;
        z-index: 1;
        
        .unbinding {
            height: 50px;
            width: 50px;
            position: absolute;
            top: 0;
            right: 0;
        }
        .top {
            display: flex;
            align-items: flex-start;
            .van-image {
                width: 82px;
                height: 68px;
                border-radius: 5px;
                margin-right: 13px;
            }
            
            .content {
                flex: 1;
                .name {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 16px;
                    width: 147px;
                }
                .detail {
                    padding: 11px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .time {
                        line-height: 11px;
                        font-size: 11px;
                        color: #999999;
                    }
                }
            }
        }
    }
    
}
</style>
