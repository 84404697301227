<template>
    <div class="list">
       <div class="item" v-for="item in list">
           <div class="card" :style="{borderRadius: item.isOpen ? '8px 8px 0 0' : '8px'}">
               <div class="top">
                   <van-image></van-image>
                   <div class="content">
                       <div class="name">长郡雨外洪塘学校小学部秋装男女同款</div>
                       <div class="detail">
                           <div class="time">2022年2月11日  11:00</div>
                           <div class="btn">去查看</div>
                       </div>
                
                
                       <div class="col" @click="item.isOpen = !item.isOpen">
                           <span>详细信息</span>
                           <img src="./../../assets/images/arrow.png" :style="{'transform': `rotateZ(${item.isOpen ? 180 : 0}deg)` }">
                       </div>
                   </div>
               </div>
           </div>
           <div class="info" v-if="item.isOpen">
               <div class="info_item">
                   下单姓名: 刘诗诗
               </div>
               <div class="info_item">
                   下单电话：18229999999
               </div>
           </div>
          
       </div>
    </div>
</template>

<script>
export default {
    name: "bindingList",
    
    data() {
        return {
            list: [{
                isOpen: true
            }, {
                isOpen: false
            }, {
                isOpen: false
            }]
        }
    }
}
</script>

<style scoped lang="less">
.list {
    
    .item {
        margin: 10px;
    }
    .card {
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 0px rgba(224,224,224,0.5000);
        border-radius: 8px;
        padding: 17px 8px 0;
        position: relative;
        z-index: 1;
        .top {
            display: flex;
            align-items: flex-start;
            .van-image {
                width: 82px;
                height: 68px;
                border-radius: 5px;
                margin-right: 13px;
            }
            
            .content {
                flex: 1;
                .name {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    line-height: 16px;
                    width: 147px;
                }
                .detail {
                    padding: 11px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px dashed #e9e9e9;
                    .time {
                        line-height: 11px;
                        font-size: 11px;
                        color: #999999;
                    }
                    
                    .btn {
                        width: 45px;
                        height: 16px;
                        background: #DB3336;
                        border-radius: 7px;
                        line-height: 16px;
                        text-align: center;
                        color: #fff;
                        font-size: 10px;
                    }
                }
            
                .col {
                    line-height: 11px;
                    font-size: 11px;
                    color: #999999;
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    img {
                        width: 8px;
                        height: 5px;
                        transition: all .2s;
                    }
                }
                
               
            }
        }
    }
    .info {
        line-height: 11px;
        font-size: 11px;
        color: #999999;
        padding: 10px 8px;
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 0px rgba(224,224,224,0.5000);
        border-radius: 0 0 8px 8px;
        transition: all .2s;
        > div {
            display: inline-block;
            margin-right: 24px;
            &:last-child {
                margin: 0;
            }
        }
    }
}
</style>
